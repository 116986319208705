import { PAYMENT_METHOD_ID, SPECIFICATION_TYPE } from '~/constants';
import { createPaymentSimulationReq, createPaymentOrderReq } from '~/utils/order/createPaymentReq';
import { createSenderOrderReq } from '~/utils/order/createSenderAddress';
import { createShipmentsSimulationReq, createShipmentsOrderReq } from '~/utils/order/createShipmentsReq';
import {
  OrderCreateRequest,
  OrderSimulationRequest,
  UserOptionType,
  GiftOption,
  DistributeSimulationRequest,
  DistributeCreateRequest,
  OrderSimulations,
} from '~/types/modules/order';
import { OrderErrorCodes, ReceiptType } from '~/enums';

/* シミュレーションAPIのリクエストを作成 */
export const createSimulationReq = (cartId: number, option: UserOptionType, giftOptions: GiftOption[]) => {
  const simulationReq: OrderSimulationRequest = {
    /* カートID */
    cart_id: cartId,
    /* 言語 Enum: "ja" "en" */
    lang_id: option.lang_id,
    /* 配送情報 */
    shipments: createShipmentsSimulationReq(option, giftOptions),
    /* 支払い情報 */
    payment: createPaymentSimulationReq(option),
    /* クーポンコード */
    coupon_code: option.couponCode,
    /* 使用ポイント */
    used_point: option.usedPoint || undefined,
  };

  return simulationReq;
};

/* 注文作成APIのリクエストを作成 */
export const createOrderReq = (
  cartId: number,
  option: UserOptionType,
  giftOptions: GiftOption[],
  simulationRes: OrderSimulations | null
): OrderCreateRequest => {
  const requiredReceipt = option.receipt.type !== ReceiptType.NONE;

  const hasIgnoredCouponError = simulationRes?.error_codes.some(
    (code) => code === OrderErrorCodes.PROMOTION_EXPIRED || code === OrderErrorCodes.PROMOTION_NOT_FOUND
  );

  const orderReq: OrderCreateRequest = {
    affiliate_tran_at: option.affiliate?.tranAt,
    affiliate_tran_id: option.affiliate?.tranId,
    /* カートID */
    cart_id: cartId,
    /* 言語 Enum: "ja" "en" */
    lang_id: option.lang_id,
    /* 配送情報 */
    shipments: createShipmentsOrderReq(option, giftOptions, simulationRes?.shipments),
    /* 決済方法 */
    payment: createPaymentOrderReq(option),
    /* クーポンエラーが出たままの時はundefinedにしてリクエスト */
    coupon_code: hasIgnoredCouponError ? undefined : option.couponCode,
    used_point: option.usedPoint || undefined,
    note: option.note || undefined,
    receipt: {
      type: option.receipt.type,
      proviso: requiredReceipt ? option.receipt.proviso : undefined,
      send_to: requiredReceipt ? option.receipt.sendTo : undefined,
    },
    sender_address: createSenderOrderReq(option),
  };

  return orderReq;
};

export const createDistributeSimulationReq = (
  distributionCourseId: number,
  option: UserOptionType
): DistributeSimulationRequest | undefined => {
  const simulationReq: DistributeSimulationRequest = {
    delivery_address_name: option.shipments[0].deliveryName,
    distribution_course_id: distributionCourseId,
    payment: option.payment.paymentMethodId
      ? {
          payment_method_id: option.payment.paymentMethodId,
        }
      : undefined,
  };

  return simulationReq;
};

export const createDistributionOrderReq = (option: UserOptionType): DistributeCreateRequest | undefined => {
  const shipment = option.shipments[0];

  if (!option.distributionCourseId || option.payment.paymentMethodId == null) {
    return;
  }

  const requiredReceipt = option.receipt.type !== ReceiptType.NONE;

  return {
    delivery_address_name:
      option.payment.paymentMethodId !== PAYMENT_METHOD_ID.AMAZON_PAY ? shipment.deliveryName : undefined,
    /* time_id: 0 → 指定なしの場合undefinedに変換してリクエスト */
    delivery_time_id: shipment.deliveryTimeId || undefined,
    distribution_course_id: option.distributionCourseId,
    has_delivery_box: shipment.hasDeliveryBox,
    lang_id: 'ja',
    note: option.note || undefined,
    payment: {
      payment_member_id:
        option.payment.paymentMethodId === PAYMENT_METHOD_ID.CREDIT_CARD && option.payment.paymentMethodId
          ? option.payment.paymentMemberId
          : undefined,
      payment_method_id: option.payment.paymentMethodId,
      payment_token: option.payment.payment_token,
    },
    specification_type: SPECIFICATION_TYPE.NONE,
    receipt: {
      type: option.receipt.type,
      proviso: requiredReceipt ? option.receipt.proviso : undefined,
      send_to: requiredReceipt ? option.receipt.sendTo : undefined,
    },
    ship_address:
      option.payment.paymentMethodId === PAYMENT_METHOD_ID.AMAZON_PAY && shipment.address?.prefecture_id
        ? { ...shipment.address, prefecture_id: shipment.address.prefecture_id }
        : undefined,
    sender_address: createSenderOrderReq(option),
  };
};
