import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _083388e8 = () => interopDefault(import('../src/pages/about.vue' /* webpackChunkName: "pages/about" */))
const _b3dd87ba = () => interopDefault(import('../src/pages/age_check.vue' /* webpackChunkName: "pages/age_check" */))
const _6be67258 = () => interopDefault(import('../src/pages/article/index.vue' /* webpackChunkName: "pages/article/index" */))
const _a331ea50 = () => interopDefault(import('../src/pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _8091e46a = () => interopDefault(import('../src/pages/cellar/index.vue' /* webpackChunkName: "pages/cellar/index" */))
const _0ac9d2aa = () => interopDefault(import('../src/pages/club.vue' /* webpackChunkName: "pages/club" */))
const _11a09d04 = () => interopDefault(import('../src/pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _54589726 = () => interopDefault(import('../src/pages/content_policy.vue' /* webpackChunkName: "pages/content_policy" */))
const _5f86ce46 = () => interopDefault(import('../src/pages/gift_wrapping.vue' /* webpackChunkName: "pages/gift_wrapping" */))
const _4305aca2 = () => interopDefault(import('../src/pages/history.vue' /* webpackChunkName: "pages/history" */))
const _2e838c00 = () => interopDefault(import('../src/pages/linkshare.vue' /* webpackChunkName: "pages/linkshare" */))
const _082d3d36 = () => interopDefault(import('../src/pages/login-with-amazon/index.vue' /* webpackChunkName: "pages/login-with-amazon/index" */))
const _2c09398e = () => interopDefault(import('../src/pages/login-with-rakuten/index.vue' /* webpackChunkName: "pages/login-with-rakuten/index" */))
const _f2a9efee = () => interopDefault(import('../src/pages/magazine.vue' /* webpackChunkName: "pages/magazine" */))
const _5c877f30 = () => interopDefault(import('../src/pages/my-wine/index.vue' /* webpackChunkName: "pages/my-wine/index" */))
const _a01cbb58 = () => interopDefault(import('../src/pages/mygift/index.vue' /* webpackChunkName: "pages/mygift/index" */))
const _131e8320 = () => interopDefault(import('../src/pages/mypage.vue' /* webpackChunkName: "pages/mypage" */))
const _7af0b0f3 = () => interopDefault(import('../src/pages/mypage/index.vue' /* webpackChunkName: "pages/mypage/index" */))
const _b7ed22e4 = () => interopDefault(import('../src/pages/mypage/account.vue' /* webpackChunkName: "pages/mypage/account" */))
const _0f2365c5 = () => interopDefault(import('../src/pages/mypage/cellar/index.vue' /* webpackChunkName: "pages/mypage/cellar/index" */))
const _c06f0e90 = () => interopDefault(import('../src/pages/mypage/dm.vue' /* webpackChunkName: "pages/mypage/dm" */))
const _86b325fa = () => interopDefault(import('../src/pages/mypage/magazine.vue' /* webpackChunkName: "pages/mypage/magazine" */))
const _8773f25e = () => interopDefault(import('../src/pages/mypage/myshop.vue' /* webpackChunkName: "pages/mypage/myshop" */))
const _e197d0e6 = () => interopDefault(import('../src/pages/mypage/password/index.vue' /* webpackChunkName: "pages/mypage/password/index" */))
const _7b00500a = () => interopDefault(import('../src/pages/mypage/profile.vue' /* webpackChunkName: "pages/mypage/profile" */))
const _00cb6d0a = () => interopDefault(import('../src/pages/mypage/cellar/list.vue' /* webpackChunkName: "pages/mypage/cellar/list" */))
const _5f065956 = () => interopDefault(import('../src/pages/mypage/cellar/sender/index.vue' /* webpackChunkName: "pages/mypage/cellar/sender/index" */))
const _84067186 = () => interopDefault(import('../src/pages/mypage/comment/list.vue' /* webpackChunkName: "pages/mypage/comment/list" */))
const _b1fc0870 = () => interopDefault(import('../src/pages/mypage/coupon/list.vue' /* webpackChunkName: "pages/mypage/coupon/list" */))
const _7c33871a = () => interopDefault(import('../src/pages/mypage/credit-card/complete.vue' /* webpackChunkName: "pages/mypage/credit-card/complete" */))
const _4c9b6258 = () => interopDefault(import('../src/pages/mypage/credit-card/list.vue' /* webpackChunkName: "pages/mypage/credit-card/list" */))
const _7a82517a = () => interopDefault(import('../src/pages/mypage/delivery/list.vue' /* webpackChunkName: "pages/mypage/delivery/list" */))
const _56e93f22 = () => interopDefault(import('../src/pages/mypage/event/list.vue' /* webpackChunkName: "pages/mypage/event/list" */))
const _416bfca8 = () => interopDefault(import('../src/pages/mypage/history/list.vue' /* webpackChunkName: "pages/mypage/history/list" */))
const _77c54593 = () => interopDefault(import('../src/pages/mypage/lottery/list.vue' /* webpackChunkName: "pages/mypage/lottery/list" */))
const _d4711964 = () => interopDefault(import('../src/pages/mypage/password/complete.vue' /* webpackChunkName: "pages/mypage/password/complete" */))
const _b6bb5e28 = () => interopDefault(import('../src/pages/mypage/point/list.vue' /* webpackChunkName: "pages/mypage/point/list" */))
const _ebbe341e = () => interopDefault(import('../src/pages/mypage/subscription/list.vue' /* webpackChunkName: "pages/mypage/subscription/list" */))
const _3c79e4e2 = () => interopDefault(import('../src/pages/mypage/cellar/billing/list.vue' /* webpackChunkName: "pages/mypage/cellar/billing/list" */))
const _866eecb8 = () => interopDefault(import('../src/pages/mypage/cellar/mix_cellar/confirm.vue' /* webpackChunkName: "pages/mypage/cellar/mix_cellar/confirm" */))
const _eae72480 = () => interopDefault(import('../src/pages/mypage/cellar/mix_cellar/delivery.vue' /* webpackChunkName: "pages/mypage/cellar/mix_cellar/delivery" */))
const _4cb73090 = () => interopDefault(import('../src/pages/mypage/cellar/mix_cellar/form.vue' /* webpackChunkName: "pages/mypage/cellar/mix_cellar/form" */))
const _58daedea = () => interopDefault(import('../src/pages/mypage/cellar/only_cellar/confirm.vue' /* webpackChunkName: "pages/mypage/cellar/only_cellar/confirm" */))
const _70c6923a = () => interopDefault(import('../src/pages/mypage/cellar/only_cellar/delivery.vue' /* webpackChunkName: "pages/mypage/cellar/only_cellar/delivery" */))
const _054abbec = () => interopDefault(import('../src/pages/mypage/cellar/only_cellar/form.vue' /* webpackChunkName: "pages/mypage/cellar/only_cellar/form" */))
const _8fd68c3a = () => interopDefault(import('../src/pages/mypage/cellar/only_cellar/gift-wrapping.vue' /* webpackChunkName: "pages/mypage/cellar/only_cellar/gift-wrapping" */))
const _89a5291e = () => interopDefault(import('../src/pages/mypage/cellar/only_cellar/paper-bag.vue' /* webpackChunkName: "pages/mypage/cellar/only_cellar/paper-bag" */))
const _8d737e92 = () => interopDefault(import('../src/pages/mypage/cellar/out/complete.vue' /* webpackChunkName: "pages/mypage/cellar/out/complete" */))
const _3410289c = () => interopDefault(import('../src/pages/mypage/cellar/out/list.vue' /* webpackChunkName: "pages/mypage/cellar/out/list" */))
const _73d1083a = () => interopDefault(import('../src/pages/mypage/cellar/sender/new.vue' /* webpackChunkName: "pages/mypage/cellar/sender/new" */))
const _59afdd1c = () => interopDefault(import('../src/pages/mypage/credit-card/detail/new.vue' /* webpackChunkName: "pages/mypage/credit-card/detail/new" */))
const _3df06ac1 = () => interopDefault(import('../src/pages/mypage/delivery/detail/new/index.vue' /* webpackChunkName: "pages/mypage/delivery/detail/new/index" */))
const _293a619a = () => interopDefault(import('../src/pages/mypage/point/detail/new/index.vue' /* webpackChunkName: "pages/mypage/point/detail/new/index" */))
const _794438cc = () => interopDefault(import('../src/pages/mypage/delivery/detail/new/complete.vue' /* webpackChunkName: "pages/mypage/delivery/detail/new/complete" */))
const _13565c30 = () => interopDefault(import('../src/pages/mypage/point/detail/new/complete.vue' /* webpackChunkName: "pages/mypage/point/detail/new/complete" */))
const _bd58503e = () => interopDefault(import('../src/pages/mypage/point/detail/new/confirm.vue' /* webpackChunkName: "pages/mypage/point/detail/new/confirm" */))
const _48ec2e2d = () => interopDefault(import('../src/pages/mypage/cellar/billing/detail/_id.vue' /* webpackChunkName: "pages/mypage/cellar/billing/detail/_id" */))
const _352be90c = () => interopDefault(import('../src/pages/mypage/cellar/out/detail/_id.vue' /* webpackChunkName: "pages/mypage/cellar/out/detail/_id" */))
const _3f3bb8b4 = () => interopDefault(import('../src/pages/mypage/event/detail/waiting/_reservationId.vue' /* webpackChunkName: "pages/mypage/event/detail/waiting/_reservationId" */))
const _562f57ca = () => interopDefault(import('../src/pages/mypage/history/shop/detail/_id.vue' /* webpackChunkName: "pages/mypage/history/shop/detail/_id" */))
const _32ffbaec = () => interopDefault(import('../src/pages/mypage/delivery/detail/edit/_.vue' /* webpackChunkName: "pages/mypage/delivery/detail/edit/_" */))
const _11730d9e = () => interopDefault(import('../src/pages/mypage/cellar/mix_cellar/_id.vue' /* webpackChunkName: "pages/mypage/cellar/mix_cellar/_id" */))
const _7a3e84e4 = () => interopDefault(import('../src/pages/mypage/cellar/only_cellar/_id.vue' /* webpackChunkName: "pages/mypage/cellar/only_cellar/_id" */))
const _45fa0836 = () => interopDefault(import('../src/pages/mypage/credit-card/detail/_id.vue' /* webpackChunkName: "pages/mypage/credit-card/detail/_id" */))
const _57c6589c = () => interopDefault(import('../src/pages/mypage/event/detail/_reservationId.vue' /* webpackChunkName: "pages/mypage/event/detail/_reservationId" */))
const _6661a686 = () => interopDefault(import('../src/pages/mypage/history/detail/_id.vue' /* webpackChunkName: "pages/mypage/history/detail/_id" */))
const _05fd5331 = () => interopDefault(import('../src/pages/mypage/lottery/detail/_id.vue' /* webpackChunkName: "pages/mypage/lottery/detail/_id" */))
const _293aa8b9 = () => interopDefault(import('../src/pages/mypage/event/reserve/_reservationId/complete.vue' /* webpackChunkName: "pages/mypage/event/reserve/_reservationId/complete" */))
const _3fdf62f0 = () => interopDefault(import('../src/pages/mypage/event/reserve/_reservationId/confirm.vue' /* webpackChunkName: "pages/mypage/event/reserve/_reservationId/confirm" */))
const _5d9318ae = () => interopDefault(import('../src/pages/mypage/event/reserve/_reservationId/date.vue' /* webpackChunkName: "pages/mypage/event/reserve/_reservationId/date" */))
const _0ef3bac4 = () => interopDefault(import('../src/pages/mypage/event/reserve/_reservationId/form.vue' /* webpackChunkName: "pages/mypage/event/reserve/_reservationId/form" */))
const _60f0c994 = () => interopDefault(import('../src/pages/mypage/event/reserve/_reservationId/payment.vue' /* webpackChunkName: "pages/mypage/event/reserve/_reservationId/payment" */))
const _89ef2744 = () => interopDefault(import('../src/pages/mypage/subscription/detail/_id/date.vue' /* webpackChunkName: "pages/mypage/subscription/detail/_id/date" */))
const _b0052cf4 = () => interopDefault(import('../src/pages/mypage/subscription/detail/_id/payment.vue' /* webpackChunkName: "pages/mypage/subscription/detail/_id/payment" */))
const _3652350c = () => interopDefault(import('../src/pages/mypage/primeur/_orderId.vue' /* webpackChunkName: "pages/mypage/primeur/_orderId" */))
const _e8fe3372 = () => interopDefault(import('../src/pages/mypage/primeur/_orderId/delivery.vue' /* webpackChunkName: "pages/mypage/primeur/_orderId/delivery" */))
const _d478b4a8 = () => interopDefault(import('../src/pages/mypage/primeur/_orderId/delivery/address.vue' /* webpackChunkName: "pages/mypage/primeur/_orderId/delivery/address" */))
const _05bf5b9e = () => interopDefault(import('../src/pages/mypage/primeur/_orderId/delivery/complete.vue' /* webpackChunkName: "pages/mypage/primeur/_orderId/delivery/complete" */))
const _073aab10 = () => interopDefault(import('../src/pages/mypage/primeur/_orderId/delivery/confirm.vue' /* webpackChunkName: "pages/mypage/primeur/_orderId/delivery/confirm" */))
const _480f83b4 = () => interopDefault(import('../src/pages/mypage/primeur/_orderId/delivery/date.vue' /* webpackChunkName: "pages/mypage/primeur/_orderId/delivery/date" */))
const _48cd37b2 = () => interopDefault(import('../src/pages/mypage/primeur/_orderId/delivery/type.vue' /* webpackChunkName: "pages/mypage/primeur/_orderId/delivery/type" */))
const _3176f6a6 = () => interopDefault(import('../src/pages/mypage/primeur/_orderId/delivery/cellar/credit.vue' /* webpackChunkName: "pages/mypage/primeur/_orderId/delivery/cellar/credit" */))
const _590cb183 = () => interopDefault(import('../src/pages/mypage/primeur/_orderId/delivery/cellar/terms.vue' /* webpackChunkName: "pages/mypage/primeur/_orderId/delivery/cellar/terms" */))
const _40b62429 = () => interopDefault(import('../src/pages/order.vue' /* webpackChunkName: "pages/order" */))
const _4d3727ec = () => interopDefault(import('../src/pages/order/index.vue' /* webpackChunkName: "pages/order/index" */))
const _ab05a07c = () => interopDefault(import('../src/pages/order/cellar-info.vue' /* webpackChunkName: "pages/order/cellar-info" */))
const _7c55928f = () => interopDefault(import('../src/pages/order/complete.vue' /* webpackChunkName: "pages/order/complete" */))
const _5aa01881 = () => interopDefault(import('../src/pages/order/delivery-date.vue' /* webpackChunkName: "pages/order/delivery-date" */))
const _3abb4bb5 = () => interopDefault(import('../src/pages/order/distribution-policy.vue' /* webpackChunkName: "pages/order/distribution-policy" */))
const _560e9e53 = () => interopDefault(import('../src/pages/order/gift-wrapping.vue' /* webpackChunkName: "pages/order/gift-wrapping" */))
const _dbe511d2 = () => interopDefault(import('../src/pages/order/leaflet.vue' /* webpackChunkName: "pages/order/leaflet" */))
const _a6e94870 = () => interopDefault(import('../src/pages/order/note.vue' /* webpackChunkName: "pages/order/note" */))
const _032b07e1 = () => interopDefault(import('../src/pages/order/paper-bag.vue' /* webpackChunkName: "pages/order/paper-bag" */))
const _0587dc44 = () => interopDefault(import('../src/pages/order/payment-method.vue' /* webpackChunkName: "pages/order/payment-method" */))
const _3fab1899 = () => interopDefault(import('../src/pages/order/primeur-policy.vue' /* webpackChunkName: "pages/order/primeur-policy" */))
const _6cda77a4 = () => interopDefault(import('../src/pages/order/receipt-attachment.vue' /* webpackChunkName: "pages/order/receipt-attachment" */))
const _2656e4b3 = () => interopDefault(import('../src/pages/order/recipient.vue' /* webpackChunkName: "pages/order/recipient" */))
const _6b3b550b = () => interopDefault(import('../src/pages/order/sender.vue' /* webpackChunkName: "pages/order/sender" */))
const _1154826e = () => interopDefault(import('../src/pages/order/signup.vue' /* webpackChunkName: "pages/order/signup" */))
const _dd7da264 = () => interopDefault(import('../src/pages/order/cb/amazon.vue' /* webpackChunkName: "pages/order/cb/amazon" */))
const _34da3f25 = () => interopDefault(import('../src/pages/order/cb/rakuten-pay.vue' /* webpackChunkName: "pages/order/cb/rakuten-pay" */))
const _4f4631fa = () => interopDefault(import('../src/pages/password-forget.vue' /* webpackChunkName: "pages/password-forget" */))
const _5cf66524 = () => interopDefault(import('../src/pages/password-forget/confirm/index.vue' /* webpackChunkName: "pages/password-forget/confirm/index" */))
const _48414ab1 = () => interopDefault(import('../src/pages/password-forget/update/index.vue' /* webpackChunkName: "pages/password-forget/update/index" */))
const _77b63466 = () => interopDefault(import('../src/pages/password-forget/confirm/complete.vue' /* webpackChunkName: "pages/password-forget/confirm/complete" */))
const _3cd9bdaa = () => interopDefault(import('../src/pages/password-forget/update/complete.vue' /* webpackChunkName: "pages/password-forget/update/complete" */))
const _0a9455e7 = () => interopDefault(import('../src/pages/policy.vue' /* webpackChunkName: "pages/policy" */))
const _3e27608c = () => interopDefault(import('../src/pages/primeur/index.vue' /* webpackChunkName: "pages/primeur/index" */))
const _b90a93d8 = () => interopDefault(import('../src/pages/ranking/index.vue' /* webpackChunkName: "pages/ranking/index" */))
const _6ca3ed17 = () => interopDefault(import('../src/pages/recommend.vue' /* webpackChunkName: "pages/recommend" */))
const _240b3790 = () => interopDefault(import('../src/pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _7109a597 = () => interopDefault(import('../src/pages/tokushoho.vue' /* webpackChunkName: "pages/tokushoho" */))
const _253262c1 = () => interopDefault(import('../src/pages/vintage.vue' /* webpackChunkName: "pages/vintage" */))
const _6be79360 = () => interopDefault(import('../src/pages/vintage_chart.vue' /* webpackChunkName: "pages/vintage_chart" */))
const _7108bad7 = () => interopDefault(import('../src/pages/archives/list.vue' /* webpackChunkName: "pages/archives/list" */))
const _354dfcd4 = () => interopDefault(import('../src/pages/auth/error.vue' /* webpackChunkName: "pages/auth/error" */))
const _6b3a8677 = () => interopDefault(import('../src/pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _492750ec = () => interopDefault(import('../src/pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _5210124f = () => interopDefault(import('../src/pages/cellar/faq.vue' /* webpackChunkName: "pages/cellar/faq" */))
const _365dc5a6 = () => interopDefault(import('../src/pages/cellar/mix_cellar.vue' /* webpackChunkName: "pages/cellar/mix_cellar" */))
const _4ebfc73f = () => interopDefault(import('../src/pages/cellar/only_cellar.vue' /* webpackChunkName: "pages/cellar/only_cellar" */))
const _7c85bcad = () => interopDefault(import('../src/pages/cellar/temperature.vue' /* webpackChunkName: "pages/cellar/temperature" */))
const _024f3786 = () => interopDefault(import('../src/pages/comment/list/index.vue' /* webpackChunkName: "pages/comment/list/index" */))
const _2de6c7bd = () => interopDefault(import('../src/pages/contact/complete.vue' /* webpackChunkName: "pages/contact/complete" */))
const _bb71d464 = () => interopDefault(import('../src/pages/contents/list.vue' /* webpackChunkName: "pages/contents/list" */))
const _140a3765 = () => interopDefault(import('../src/pages/food-diagnosis/result.vue' /* webpackChunkName: "pages/food-diagnosis/result" */))
const _704b51cd = () => interopDefault(import('../src/pages/food-diagnosis/step1.vue' /* webpackChunkName: "pages/food-diagnosis/step1" */))
const _7059694e = () => interopDefault(import('../src/pages/food-diagnosis/step2.vue' /* webpackChunkName: "pages/food-diagnosis/step2" */))
const _706780cf = () => interopDefault(import('../src/pages/food-diagnosis/step3.vue' /* webpackChunkName: "pages/food-diagnosis/step3" */))
const _5ca8fc3d = () => interopDefault(import('../src/pages/food-diagnosis/top.vue' /* webpackChunkName: "pages/food-diagnosis/top" */))
const _4b367775 = () => interopDefault(import('../src/pages/item/list.vue' /* webpackChunkName: "pages/item/list" */))
const _5650c3bf = () => interopDefault(import('../src/pages/item/search.vue' /* webpackChunkName: "pages/item/search" */))
const _3bfd1af9 = () => interopDefault(import('../src/pages/keyword/list.vue' /* webpackChunkName: "pages/keyword/list" */))
const _57c0a01a = () => interopDefault(import('../src/pages/my-wine/notes/index.vue' /* webpackChunkName: "pages/my-wine/notes/index" */))
const _1401bf4a = () => interopDefault(import('../src/pages/my-wine/wine-diagnosis/index.vue' /* webpackChunkName: "pages/my-wine/wine-diagnosis/index" */))
const _42873b80 = () => interopDefault(import('../src/pages/mygift/deliveries.vue' /* webpackChunkName: "pages/mygift/deliveries" */))
const _16169c5c = () => interopDefault(import('../src/pages/mygift/products.vue' /* webpackChunkName: "pages/mygift/products" */))
const _9babc096 = () => interopDefault(import('../src/pages/news/list.vue' /* webpackChunkName: "pages/news/list" */))
const _da7c6634 = () => interopDefault(import('../src/pages/primeur/list.vue' /* webpackChunkName: "pages/primeur/list" */))
const _1e4cc6f8 = () => interopDefault(import('../src/pages/primeur/loginSection.vue' /* webpackChunkName: "pages/primeur/loginSection" */))
const _71b0eb7a = () => interopDefault(import('../src/pages/primeur/policy.vue' /* webpackChunkName: "pages/primeur/policy" */))
const _ea2be422 = () => interopDefault(import('../src/pages/primeur/terms.vue' /* webpackChunkName: "pages/primeur/terms" */))
const _fd0baf54 = () => interopDefault(import('../src/pages/producer/list.vue' /* webpackChunkName: "pages/producer/list" */))
const _322b9e5a = () => interopDefault(import('../src/pages/ranking/red.vue' /* webpackChunkName: "pages/ranking/red" */))
const _4b76ec15 = () => interopDefault(import('../src/pages/ranking/sparkling.vue' /* webpackChunkName: "pages/ranking/sparkling" */))
const _70fdd5eb = () => interopDefault(import('../src/pages/ranking/white.vue' /* webpackChunkName: "pages/ranking/white" */))
const _2e83a2fb = () => interopDefault(import('../src/pages/ranking/wineset.vue' /* webpackChunkName: "pages/ranking/wineset" */))
const _084d1af6 = () => interopDefault(import('../src/pages/scene/list.vue' /* webpackChunkName: "pages/scene/list" */))
const _0032c97d = () => interopDefault(import('../src/pages/shop/all.vue' /* webpackChunkName: "pages/shop/all" */))
const _8e284c0e = () => interopDefault(import('../src/pages/shop/blog/index.vue' /* webpackChunkName: "pages/shop/blog/index" */))
const _4c1db06b = () => interopDefault(import('../src/pages/signup/complete.vue' /* webpackChunkName: "pages/signup/complete" */))
const _78939926 = () => interopDefault(import('../src/pages/staff-review/list/index.vue' /* webpackChunkName: "pages/staff-review/list/index" */))
const _0d5dcf22 = () => interopDefault(import('../src/pages/staff/list.vue' /* webpackChunkName: "pages/staff/list" */))
const _f544e056 = () => interopDefault(import('../src/pages/webview/mylist.vue' /* webpackChunkName: "pages/webview/mylist" */))
const _300df0a4 = () => interopDefault(import('../src/pages/wine/area.vue' /* webpackChunkName: "pages/wine/area" */))
const _4caeb70c = () => interopDefault(import('../src/pages/wine/grape.vue' /* webpackChunkName: "pages/wine/grape" */))
const _e7091796 = () => interopDefault(import('../src/pages/auth/cb/success.vue' /* webpackChunkName: "pages/auth/cb/success" */))
const _6d48c5c6 = () => interopDefault(import('../src/pages/cellar/form/complete.vue' /* webpackChunkName: "pages/cellar/form/complete" */))
const _6ea639dd = () => interopDefault(import('../src/pages/cellar/form/credit.vue' /* webpackChunkName: "pages/cellar/form/credit" */))
const _217720d4 = () => interopDefault(import('../src/pages/cellar/form/policy.vue' /* webpackChunkName: "pages/cellar/form/policy" */))
const _630c8d73 = () => interopDefault(import('../src/pages/dcon/social/login.vue' /* webpackChunkName: "pages/dcon/social/login" */))
const _2a8142c2 = () => interopDefault(import('../src/pages/dcon/social/merge.vue' /* webpackChunkName: "pages/dcon/social/merge" */))
const _352dcb47 = () => interopDefault(import('../src/pages/my-wine/favorite/list.vue' /* webpackChunkName: "pages/my-wine/favorite/list" */))
const _7e6a534a = () => interopDefault(import('../src/pages/my-wine/personalReports/show/index.vue' /* webpackChunkName: "pages/my-wine/personalReports/show/index" */))
const _67da92de = () => interopDefault(import('../src/pages/my-wine/purchase-history/list/index.vue' /* webpackChunkName: "pages/my-wine/purchase-history/list/index" */))
const _7379347e = () => interopDefault(import('../src/pages/my-wine/wine-diagnosis/today-mood/index.vue' /* webpackChunkName: "pages/my-wine/wine-diagnosis/today-mood/index" */))
const _cc42866c = () => interopDefault(import('../src/pages/my-wine/wine-diagnosis/your-wine-types/index.vue' /* webpackChunkName: "pages/my-wine/wine-diagnosis/your-wine-types/index" */))
const _e54a47f0 = () => interopDefault(import('../src/pages/shop/event/all.vue' /* webpackChunkName: "pages/shop/event/all" */))
const _07ce3966 = () => interopDefault(import('../src/pages/veritrans/callback/order.vue' /* webpackChunkName: "pages/veritrans/callback/order" */))
const _6890c18e = () => interopDefault(import('../src/pages/wine-college/quiz/daily/index.vue' /* webpackChunkName: "pages/wine-college/quiz/daily/index" */))
const _883d8ba6 = () => interopDefault(import('../src/pages/wine-college/basic/lesson/list.vue' /* webpackChunkName: "pages/wine-college/basic/lesson/list" */))
const _b70b5ae2 = () => interopDefault(import('../src/pages/wine-college/lesson/category/list.vue' /* webpackChunkName: "pages/wine-college/lesson/category/list" */))
const _01cf321a = () => interopDefault(import('../src/pages/wine-college/producing-area/lesson/list.vue' /* webpackChunkName: "pages/wine-college/producing-area/lesson/list" */))
const _b2b73a10 = () => interopDefault(import('../src/pages/wine-college/quiz/daily/page.vue' /* webpackChunkName: "pages/wine-college/quiz/daily/page" */))
const _5f3e4146 = () => interopDefault(import('../src/pages/wine-college/quiz/daily/result.vue' /* webpackChunkName: "pages/wine-college/quiz/daily/result" */))
const _5bd3ed40 = () => interopDefault(import('../src/pages/article/archives/author/_permalink.vue' /* webpackChunkName: "pages/article/archives/author/_permalink" */))
const _e4c82126 = () => interopDefault(import('../src/pages/article/archives/category/_permalink.vue' /* webpackChunkName: "pages/article/archives/category/_permalink" */))
const _0389275a = () => interopDefault(import('../src/pages/comment/list/user/_commentId.vue' /* webpackChunkName: "pages/comment/list/user/_commentId" */))
const _09d30b38 = () => interopDefault(import('../src/pages/item/detail/distribution/_code.vue' /* webpackChunkName: "pages/item/detail/distribution/_code" */))
const _09662e4e = () => interopDefault(import('../src/pages/shop/event/reserve/_id.vue' /* webpackChunkName: "pages/shop/event/reserve/_id" */))
const _b2a3bbec = () => interopDefault(import('../src/pages/shop/event/reserve/_id/complete.vue' /* webpackChunkName: "pages/shop/event/reserve/_id/complete" */))
const _125efb7f = () => interopDefault(import('../src/pages/shop/event/reserve/_id/confirm.vue' /* webpackChunkName: "pages/shop/event/reserve/_id/confirm" */))
const _2e11e27f = () => interopDefault(import('../src/pages/shop/event/reserve/_id/date.vue' /* webpackChunkName: "pages/shop/event/reserve/_id/date" */))
const _411af6d6 = () => interopDefault(import('../src/pages/shop/event/reserve/_id/form.vue' /* webpackChunkName: "pages/shop/event/reserve/_id/form" */))
const _bbf19876 = () => interopDefault(import('../src/pages/shop/event/reserve/_id/payment.vue' /* webpackChunkName: "pages/shop/event/reserve/_id/payment" */))
const _319714b9 = () => interopDefault(import('../src/pages/staff-review/list/product/_productCode.vue' /* webpackChunkName: "pages/staff-review/list/product/_productCode" */))
const _de307684 = () => interopDefault(import('../src/pages/wine-college/basic/lesson/_id/index.vue' /* webpackChunkName: "pages/wine-college/basic/lesson/_id/index" */))
const _0888b8f1 = () => interopDefault(import('../src/pages/wine-college/producing-area/lesson/_id/index.vue' /* webpackChunkName: "pages/wine-college/producing-area/lesson/_id/index" */))
const _466666ce = () => interopDefault(import('../src/pages/shop/event/waiting/_id/complete.vue' /* webpackChunkName: "pages/shop/event/waiting/_id/complete" */))
const _41f2cea4 = () => interopDefault(import('../src/pages/shop/event/waiting/_id/form.vue' /* webpackChunkName: "pages/shop/event/waiting/_id/form" */))
const _0e04cc9c = () => interopDefault(import('../src/pages/wine-college/basic/lesson/_id/page/_page.vue' /* webpackChunkName: "pages/wine-college/basic/lesson/_id/page/_page" */))
const _3525e516 = () => interopDefault(import('../src/pages/wine-college/basic/quiz/_id/page/_page.vue' /* webpackChunkName: "pages/wine-college/basic/quiz/_id/page/_page" */))
const _1014cc7a = () => interopDefault(import('../src/pages/wine-college/basic/quiz/_id/result/_page.vue' /* webpackChunkName: "pages/wine-college/basic/quiz/_id/result/_page" */))
const _0a476542 = () => interopDefault(import('../src/pages/wine-college/producing-area/lesson/_id/page/_page.vue' /* webpackChunkName: "pages/wine-college/producing-area/lesson/_id/page/_page" */))
const _38cec862 = () => interopDefault(import('../src/pages/wine-college/producing-area/quiz/_id/page/_page.vue' /* webpackChunkName: "pages/wine-college/producing-area/quiz/_id/page/_page" */))
const _0c576520 = () => interopDefault(import('../src/pages/wine-college/producing-area/quiz/_id/result/_page.vue' /* webpackChunkName: "pages/wine-college/producing-area/quiz/_id/result/_page" */))
const _cfd832fe = () => interopDefault(import('../src/pages/archives/detail/_permalink.vue' /* webpackChunkName: "pages/archives/detail/_permalink" */))
const _0e090268 = () => interopDefault(import('../src/pages/article/archives/_permalink.vue' /* webpackChunkName: "pages/article/archives/_permalink" */))
const _4c3eca28 = () => interopDefault(import('../src/pages/item/detail/_code/index.vue' /* webpackChunkName: "pages/item/detail/_code/index" */))
const _10985eb7 = () => interopDefault(import('../src/pages/lottery/detail/_id.vue' /* webpackChunkName: "pages/lottery/detail/_id" */))
const _75a9da63 = () => interopDefault(import('../src/pages/news/detail/_permalink.vue' /* webpackChunkName: "pages/news/detail/_permalink" */))
const _355e77d6 = () => interopDefault(import('../src/pages/primeur/detail/_code.vue' /* webpackChunkName: "pages/primeur/detail/_code" */))
const _f7405098 = () => interopDefault(import('../src/pages/producer/detail/_id.vue' /* webpackChunkName: "pages/producer/detail/_id" */))
const _1bba62d0 = () => interopDefault(import('../src/pages/shop/blog/_permalink/index.vue' /* webpackChunkName: "pages/shop/blog/_permalink/index" */))
const _c6f8ad38 = () => interopDefault(import('../src/pages/shop/event/_id/index.vue' /* webpackChunkName: "pages/shop/event/_id/index" */))
const _7123e979 = () => interopDefault(import('../src/pages/staff-review/detail/_reviewId.vue' /* webpackChunkName: "pages/staff-review/detail/_reviewId" */))
const _46f163f4 = () => interopDefault(import('../src/pages/staff/detail/_staffId.vue' /* webpackChunkName: "pages/staff/detail/_staffId" */))
const _818a386e = () => interopDefault(import('../src/pages/item/detail/_code/comment/new/index.vue' /* webpackChunkName: "pages/item/detail/_code/comment/new/index" */))
const _7cd86d92 = () => interopDefault(import('../src/pages/item/detail/_code/comment/new/complete.vue' /* webpackChunkName: "pages/item/detail/_code/comment/new/complete" */))
const _410dc212 = () => interopDefault(import('../src/pages/item/detail/_code/comment/new/confirm.vue' /* webpackChunkName: "pages/item/detail/_code/comment/new/confirm" */))
const _8ad4ad14 = () => interopDefault(import('../src/pages/shop/blog/_permalink/detail/_articleId.vue' /* webpackChunkName: "pages/shop/blog/_permalink/detail/_articleId" */))
const _956e1080 = () => interopDefault(import('../src/pages/shop/_permalink.vue' /* webpackChunkName: "pages/shop/_permalink" */))
const _17e702e1 = () => interopDefault(import('../src/pages/feature/_.vue' /* webpackChunkName: "pages/feature/_" */))
const _27eb63ad = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _083388e8,
    name: "about"
  }, {
    path: "/age_check",
    component: _b3dd87ba,
    name: "age_check"
  }, {
    path: "/article",
    component: _6be67258,
    name: "article"
  }, {
    path: "/cart",
    component: _a331ea50,
    name: "cart"
  }, {
    path: "/cellar",
    component: _8091e46a,
    name: "cellar"
  }, {
    path: "/club",
    component: _0ac9d2aa,
    name: "club"
  }, {
    path: "/contact",
    component: _11a09d04,
    name: "contact"
  }, {
    path: "/content_policy",
    component: _54589726,
    name: "content_policy"
  }, {
    path: "/gift_wrapping",
    component: _5f86ce46,
    name: "gift_wrapping"
  }, {
    path: "/history",
    component: _4305aca2,
    name: "history"
  }, {
    path: "/linkshare",
    component: _2e838c00,
    name: "linkshare"
  }, {
    path: "/login-with-amazon",
    component: _082d3d36,
    name: "login-with-amazon"
  }, {
    path: "/login-with-rakuten",
    component: _2c09398e,
    name: "login-with-rakuten"
  }, {
    path: "/magazine",
    component: _f2a9efee,
    name: "magazine"
  }, {
    path: "/my-wine",
    component: _5c877f30,
    name: "my-wine"
  }, {
    path: "/mygift",
    component: _a01cbb58,
    name: "mygift"
  }, {
    path: "/mypage",
    component: _131e8320,
    children: [{
      path: "",
      component: _7af0b0f3,
      name: "mypage"
    }, {
      path: "account",
      component: _b7ed22e4,
      name: "mypage-account"
    }, {
      path: "cellar",
      component: _0f2365c5,
      name: "mypage-cellar"
    }, {
      path: "dm",
      component: _c06f0e90,
      name: "mypage-dm"
    }, {
      path: "magazine",
      component: _86b325fa,
      name: "mypage-magazine"
    }, {
      path: "myshop",
      component: _8773f25e,
      name: "mypage-myshop"
    }, {
      path: "password",
      component: _e197d0e6,
      name: "mypage-password"
    }, {
      path: "profile",
      component: _7b00500a,
      name: "mypage-profile"
    }, {
      path: "cellar/list",
      component: _00cb6d0a,
      name: "mypage-cellar-list"
    }, {
      path: "cellar/sender",
      component: _5f065956,
      name: "mypage-cellar-sender"
    }, {
      path: "comment/list",
      component: _84067186,
      name: "mypage-comment-list"
    }, {
      path: "coupon/list",
      component: _b1fc0870,
      name: "mypage-coupon-list"
    }, {
      path: "credit-card/complete",
      component: _7c33871a,
      name: "mypage-credit-card-complete"
    }, {
      path: "credit-card/list",
      component: _4c9b6258,
      name: "mypage-credit-card-list"
    }, {
      path: "delivery/list",
      component: _7a82517a,
      name: "mypage-delivery-list"
    }, {
      path: "event/list",
      component: _56e93f22,
      name: "mypage-event-list"
    }, {
      path: "history/list",
      component: _416bfca8,
      name: "mypage-history-list"
    }, {
      path: "lottery/list",
      component: _77c54593,
      name: "mypage-lottery-list"
    }, {
      path: "password/complete",
      component: _d4711964,
      name: "mypage-password-complete"
    }, {
      path: "point/list",
      component: _b6bb5e28,
      name: "mypage-point-list"
    }, {
      path: "subscription/list",
      component: _ebbe341e,
      name: "mypage-subscription-list"
    }, {
      path: "cellar/billing/list",
      component: _3c79e4e2,
      name: "mypage-cellar-billing-list"
    }, {
      path: "cellar/mix_cellar/confirm",
      component: _866eecb8,
      name: "mypage-cellar-mix_cellar-confirm"
    }, {
      path: "cellar/mix_cellar/delivery",
      component: _eae72480,
      name: "mypage-cellar-mix_cellar-delivery"
    }, {
      path: "cellar/mix_cellar/form",
      component: _4cb73090,
      name: "mypage-cellar-mix_cellar-form"
    }, {
      path: "cellar/only_cellar/confirm",
      component: _58daedea,
      name: "mypage-cellar-only_cellar-confirm"
    }, {
      path: "cellar/only_cellar/delivery",
      component: _70c6923a,
      name: "mypage-cellar-only_cellar-delivery"
    }, {
      path: "cellar/only_cellar/form",
      component: _054abbec,
      name: "mypage-cellar-only_cellar-form"
    }, {
      path: "cellar/only_cellar/gift-wrapping",
      component: _8fd68c3a,
      name: "mypage-cellar-only_cellar-gift-wrapping"
    }, {
      path: "cellar/only_cellar/paper-bag",
      component: _89a5291e,
      name: "mypage-cellar-only_cellar-paper-bag"
    }, {
      path: "cellar/out/complete",
      component: _8d737e92,
      name: "mypage-cellar-out-complete"
    }, {
      path: "cellar/out/list",
      component: _3410289c,
      name: "mypage-cellar-out-list"
    }, {
      path: "cellar/sender/new",
      component: _73d1083a,
      name: "mypage-cellar-sender-new"
    }, {
      path: "credit-card/detail/new",
      component: _59afdd1c,
      name: "mypage-credit-card-detail-new"
    }, {
      path: "delivery/detail/new",
      component: _3df06ac1,
      name: "mypage-delivery-detail-new"
    }, {
      path: "point/detail/new",
      component: _293a619a,
      name: "mypage-point-detail-new"
    }, {
      path: "delivery/detail/new/complete",
      component: _794438cc,
      name: "mypage-delivery-detail-new-complete"
    }, {
      path: "point/detail/new/complete",
      component: _13565c30,
      name: "mypage-point-detail-new-complete"
    }, {
      path: "point/detail/new/confirm",
      component: _bd58503e,
      name: "mypage-point-detail-new-confirm"
    }, {
      path: "cellar/billing/detail/:id?",
      component: _48ec2e2d,
      name: "mypage-cellar-billing-detail-id"
    }, {
      path: "cellar/out/detail/:id?",
      component: _352be90c,
      name: "mypage-cellar-out-detail-id"
    }, {
      path: "event/detail/waiting/:reservationId?",
      component: _3f3bb8b4,
      name: "mypage-event-detail-waiting-reservationId"
    }, {
      path: "history/shop/detail/:id?",
      component: _562f57ca,
      name: "mypage-history-shop-detail-id"
    }, {
      path: "delivery/detail/edit/*",
      component: _32ffbaec,
      name: "mypage-delivery-detail-edit-all"
    }, {
      path: "cellar/mix_cellar/:id?",
      component: _11730d9e,
      name: "mypage-cellar-mix_cellar-id"
    }, {
      path: "cellar/only_cellar/:id?",
      component: _7a3e84e4,
      name: "mypage-cellar-only_cellar-id"
    }, {
      path: "credit-card/detail/:id?",
      component: _45fa0836,
      name: "mypage-credit-card-detail-id"
    }, {
      path: "event/detail/:reservationId?",
      component: _57c6589c,
      name: "mypage-event-detail-reservationId"
    }, {
      path: "history/detail/:id?",
      component: _6661a686,
      name: "mypage-history-detail-id"
    }, {
      path: "lottery/detail/:id?",
      component: _05fd5331,
      name: "mypage-lottery-detail-id"
    }, {
      path: "event/reserve/:reservationId?/complete",
      component: _293aa8b9,
      name: "mypage-event-reserve-reservationId-complete"
    }, {
      path: "event/reserve/:reservationId?/confirm",
      component: _3fdf62f0,
      name: "mypage-event-reserve-reservationId-confirm"
    }, {
      path: "event/reserve/:reservationId?/date",
      component: _5d9318ae,
      name: "mypage-event-reserve-reservationId-date"
    }, {
      path: "event/reserve/:reservationId?/form",
      component: _0ef3bac4,
      name: "mypage-event-reserve-reservationId-form"
    }, {
      path: "event/reserve/:reservationId?/payment",
      component: _60f0c994,
      name: "mypage-event-reserve-reservationId-payment"
    }, {
      path: "subscription/detail/:id?/date",
      component: _89ef2744,
      name: "mypage-subscription-detail-id-date"
    }, {
      path: "subscription/detail/:id?/payment",
      component: _b0052cf4,
      name: "mypage-subscription-detail-id-payment"
    }, {
      path: "primeur/:orderId?",
      component: _3652350c,
      name: "mypage-primeur-orderId",
      children: [{
        path: "delivery",
        component: _e8fe3372,
        name: "mypage-primeur-orderId-delivery",
        children: [{
          path: "address",
          component: _d478b4a8,
          name: "mypage-primeur-orderId-delivery-address"
        }, {
          path: "complete",
          component: _05bf5b9e,
          name: "mypage-primeur-orderId-delivery-complete"
        }, {
          path: "confirm",
          component: _073aab10,
          name: "mypage-primeur-orderId-delivery-confirm"
        }, {
          path: "date",
          component: _480f83b4,
          name: "mypage-primeur-orderId-delivery-date"
        }, {
          path: "type",
          component: _48cd37b2,
          name: "mypage-primeur-orderId-delivery-type"
        }, {
          path: "cellar/credit",
          component: _3176f6a6,
          name: "mypage-primeur-orderId-delivery-cellar-credit"
        }, {
          path: "cellar/terms",
          component: _590cb183,
          name: "mypage-primeur-orderId-delivery-cellar-terms"
        }]
      }]
    }]
  }, {
    path: "/order",
    component: _40b62429,
    children: [{
      path: "",
      component: _4d3727ec,
      name: "order"
    }, {
      path: "cellar-info",
      component: _ab05a07c,
      name: "order-cellar-info"
    }, {
      path: "complete",
      component: _7c55928f,
      name: "order-complete"
    }, {
      path: "delivery-date",
      component: _5aa01881,
      name: "order-delivery-date"
    }, {
      path: "distribution-policy",
      component: _3abb4bb5,
      name: "order-distribution-policy"
    }, {
      path: "gift-wrapping",
      component: _560e9e53,
      name: "order-gift-wrapping"
    }, {
      path: "leaflet",
      component: _dbe511d2,
      name: "order-leaflet"
    }, {
      path: "note",
      component: _a6e94870,
      name: "order-note"
    }, {
      path: "paper-bag",
      component: _032b07e1,
      name: "order-paper-bag"
    }, {
      path: "payment-method",
      component: _0587dc44,
      name: "order-payment-method"
    }, {
      path: "primeur-policy",
      component: _3fab1899,
      name: "order-primeur-policy"
    }, {
      path: "receipt-attachment",
      component: _6cda77a4,
      name: "order-receipt-attachment"
    }, {
      path: "recipient",
      component: _2656e4b3,
      name: "order-recipient"
    }, {
      path: "sender",
      component: _6b3b550b,
      name: "order-sender"
    }, {
      path: "signup",
      component: _1154826e,
      name: "order-signup"
    }, {
      path: "cb/amazon",
      component: _dd7da264,
      name: "order-cb-amazon"
    }, {
      path: "cb/rakuten-pay",
      component: _34da3f25,
      name: "order-cb-rakuten-pay"
    }]
  }, {
    path: "/password-forget",
    component: _4f4631fa,
    name: "password-forget",
    children: [{
      path: "confirm",
      component: _5cf66524,
      name: "password-forget-confirm"
    }, {
      path: "update",
      component: _48414ab1,
      name: "password-forget-update"
    }, {
      path: "confirm/complete",
      component: _77b63466,
      name: "password-forget-confirm-complete"
    }, {
      path: "update/complete",
      component: _3cd9bdaa,
      name: "password-forget-update-complete"
    }]
  }, {
    path: "/policy",
    component: _0a9455e7,
    name: "policy"
  }, {
    path: "/primeur",
    component: _3e27608c,
    name: "primeur"
  }, {
    path: "/ranking",
    component: _b90a93d8,
    name: "ranking"
  }, {
    path: "/recommend",
    component: _6ca3ed17,
    name: "recommend"
  }, {
    path: "/signup",
    component: _240b3790,
    name: "signup"
  }, {
    path: "/tokushoho",
    component: _7109a597,
    name: "tokushoho"
  }, {
    path: "/vintage",
    component: _253262c1,
    name: "vintage"
  }, {
    path: "/vintage_chart",
    component: _6be79360,
    name: "vintage_chart"
  }, {
    path: "/archives/list",
    component: _7108bad7,
    name: "archives-list"
  }, {
    path: "/auth/error",
    component: _354dfcd4,
    name: "auth-error"
  }, {
    path: "/auth/login",
    component: _6b3a8677,
    name: "auth-login"
  }, {
    path: "/auth/logout",
    component: _492750ec,
    name: "auth-logout"
  }, {
    path: "/cellar/faq",
    component: _5210124f,
    name: "cellar-faq"
  }, {
    path: "/cellar/mix_cellar",
    component: _365dc5a6,
    name: "cellar-mix_cellar"
  }, {
    path: "/cellar/only_cellar",
    component: _4ebfc73f,
    name: "cellar-only_cellar"
  }, {
    path: "/cellar/temperature",
    component: _7c85bcad,
    name: "cellar-temperature"
  }, {
    path: "/comment/list",
    component: _024f3786,
    name: "comment-list"
  }, {
    path: "/contact/complete",
    component: _2de6c7bd,
    name: "contact-complete"
  }, {
    path: "/contents/list",
    component: _bb71d464,
    name: "contents-list"
  }, {
    path: "/food-diagnosis/result",
    component: _140a3765,
    name: "food-diagnosis-result"
  }, {
    path: "/food-diagnosis/step1",
    component: _704b51cd,
    name: "food-diagnosis-step1"
  }, {
    path: "/food-diagnosis/step2",
    component: _7059694e,
    name: "food-diagnosis-step2"
  }, {
    path: "/food-diagnosis/step3",
    component: _706780cf,
    name: "food-diagnosis-step3"
  }, {
    path: "/food-diagnosis/top",
    component: _5ca8fc3d,
    name: "food-diagnosis-top"
  }, {
    path: "/item/list",
    component: _4b367775,
    name: "item-list"
  }, {
    path: "/item/search",
    component: _5650c3bf,
    name: "item-search"
  }, {
    path: "/keyword/list",
    component: _3bfd1af9,
    name: "keyword-list"
  }, {
    path: "/my-wine/notes",
    component: _57c0a01a,
    name: "my-wine-notes"
  }, {
    path: "/my-wine/wine-diagnosis",
    component: _1401bf4a,
    name: "my-wine-wine-diagnosis"
  }, {
    path: "/mygift/deliveries",
    component: _42873b80,
    name: "mygift-deliveries"
  }, {
    path: "/mygift/products",
    component: _16169c5c,
    name: "mygift-products"
  }, {
    path: "/news/list",
    component: _9babc096,
    name: "news-list"
  }, {
    path: "/primeur/list",
    component: _da7c6634,
    name: "primeur-list"
  }, {
    path: "/primeur/loginSection",
    component: _1e4cc6f8,
    name: "primeur-loginSection"
  }, {
    path: "/primeur/policy",
    component: _71b0eb7a,
    name: "primeur-policy"
  }, {
    path: "/primeur/terms",
    component: _ea2be422,
    name: "primeur-terms"
  }, {
    path: "/producer/list",
    component: _fd0baf54,
    name: "producer-list"
  }, {
    path: "/ranking/red",
    component: _322b9e5a,
    name: "ranking-red"
  }, {
    path: "/ranking/sparkling",
    component: _4b76ec15,
    name: "ranking-sparkling"
  }, {
    path: "/ranking/white",
    component: _70fdd5eb,
    name: "ranking-white"
  }, {
    path: "/ranking/wineset",
    component: _2e83a2fb,
    name: "ranking-wineset"
  }, {
    path: "/scene/list",
    component: _084d1af6,
    name: "scene-list"
  }, {
    path: "/shop/all",
    component: _0032c97d,
    name: "shop-all"
  }, {
    path: "/shop/blog",
    component: _8e284c0e,
    name: "shop-blog"
  }, {
    path: "/signup/complete",
    component: _4c1db06b,
    name: "signup-complete"
  }, {
    path: "/staff-review/list",
    component: _78939926,
    name: "staff-review-list"
  }, {
    path: "/staff/list",
    component: _0d5dcf22,
    name: "staff-list"
  }, {
    path: "/webview/mylist",
    component: _f544e056,
    name: "webview-mylist"
  }, {
    path: "/wine/area",
    component: _300df0a4,
    name: "wine-area"
  }, {
    path: "/wine/grape",
    component: _4caeb70c,
    name: "wine-grape"
  }, {
    path: "/auth/cb/success",
    component: _e7091796,
    name: "auth-cb-success"
  }, {
    path: "/cellar/form/complete",
    component: _6d48c5c6,
    name: "cellar-form-complete"
  }, {
    path: "/cellar/form/credit",
    component: _6ea639dd,
    name: "cellar-form-credit"
  }, {
    path: "/cellar/form/policy",
    component: _217720d4,
    name: "cellar-form-policy"
  }, {
    path: "/dcon/social/login",
    component: _630c8d73,
    name: "dcon-social-login"
  }, {
    path: "/dcon/social/merge",
    component: _2a8142c2,
    name: "dcon-social-merge"
  }, {
    path: "/my-wine/favorite/list",
    component: _352dcb47,
    name: "my-wine-favorite-list"
  }, {
    path: "/my-wine/personalReports/show",
    component: _7e6a534a,
    name: "my-wine-personalReports-show"
  }, {
    path: "/my-wine/purchase-history/list",
    component: _67da92de,
    name: "my-wine-purchase-history-list"
  }, {
    path: "/my-wine/wine-diagnosis/today-mood",
    component: _7379347e,
    name: "my-wine-wine-diagnosis-today-mood"
  }, {
    path: "/my-wine/wine-diagnosis/your-wine-types",
    component: _cc42866c,
    name: "my-wine-wine-diagnosis-your-wine-types"
  }, {
    path: "/shop/event/all",
    component: _e54a47f0,
    name: "shop-event-all"
  }, {
    path: "/veritrans/callback/order",
    component: _07ce3966,
    name: "veritrans-callback-order"
  }, {
    path: "/wine-college/quiz/daily",
    component: _6890c18e,
    name: "wine-college-quiz-daily"
  }, {
    path: "/wine-college/basic/lesson/list",
    component: _883d8ba6,
    name: "wine-college-basic-lesson-list"
  }, {
    path: "/wine-college/lesson/category/list",
    component: _b70b5ae2,
    name: "wine-college-lesson-category-list"
  }, {
    path: "/wine-college/producing-area/lesson/list",
    component: _01cf321a,
    name: "wine-college-producing-area-lesson-list"
  }, {
    path: "/wine-college/quiz/daily/page",
    component: _b2b73a10,
    name: "wine-college-quiz-daily-page"
  }, {
    path: "/wine-college/quiz/daily/result",
    component: _5f3e4146,
    name: "wine-college-quiz-daily-result"
  }, {
    path: "/article/archives/author/:permalink?",
    component: _5bd3ed40,
    name: "article-archives-author-permalink"
  }, {
    path: "/article/archives/category/:permalink?",
    component: _e4c82126,
    name: "article-archives-category-permalink"
  }, {
    path: "/comment/list/user/:commentId?",
    component: _0389275a,
    name: "comment-list-user-commentId"
  }, {
    path: "/item/detail/distribution/:code?",
    component: _09d30b38,
    name: "item-detail-distribution-code"
  }, {
    path: "/shop/event/reserve/:id?",
    component: _09662e4e,
    name: "shop-event-reserve-id",
    children: [{
      path: "complete",
      component: _b2a3bbec,
      name: "shop-event-reserve-id-complete"
    }, {
      path: "confirm",
      component: _125efb7f,
      name: "shop-event-reserve-id-confirm"
    }, {
      path: "date",
      component: _2e11e27f,
      name: "shop-event-reserve-id-date"
    }, {
      path: "form",
      component: _411af6d6,
      name: "shop-event-reserve-id-form"
    }, {
      path: "payment",
      component: _bbf19876,
      name: "shop-event-reserve-id-payment"
    }]
  }, {
    path: "/staff-review/list/product/:productCode",
    component: _319714b9,
    name: "staff-review-list-product-productCode"
  }, {
    path: "/wine-college/basic/lesson/:id",
    component: _de307684,
    name: "wine-college-basic-lesson-id"
  }, {
    path: "/wine-college/producing-area/lesson/:id",
    component: _0888b8f1,
    name: "wine-college-producing-area-lesson-id"
  }, {
    path: "/shop/event/waiting/:id?/complete",
    component: _466666ce,
    name: "shop-event-waiting-id-complete"
  }, {
    path: "/shop/event/waiting/:id?/form",
    component: _41f2cea4,
    name: "shop-event-waiting-id-form"
  }, {
    path: "/wine-college/basic/lesson/:id?/page/:page",
    component: _0e04cc9c,
    name: "wine-college-basic-lesson-id-page-page"
  }, {
    path: "/wine-college/basic/quiz/:id?/page/:page?",
    component: _3525e516,
    name: "wine-college-basic-quiz-id-page-page"
  }, {
    path: "/wine-college/basic/quiz/:id?/result/:page?",
    component: _1014cc7a,
    name: "wine-college-basic-quiz-id-result-page"
  }, {
    path: "/wine-college/producing-area/lesson/:id?/page/:page?",
    component: _0a476542,
    name: "wine-college-producing-area-lesson-id-page-page"
  }, {
    path: "/wine-college/producing-area/quiz/:id?/page/:page?",
    component: _38cec862,
    name: "wine-college-producing-area-quiz-id-page-page"
  }, {
    path: "/wine-college/producing-area/quiz/:id?/result/:page?",
    component: _0c576520,
    name: "wine-college-producing-area-quiz-id-result-page"
  }, {
    path: "/archives/detail/:permalink?",
    component: _cfd832fe,
    name: "archives-detail-permalink"
  }, {
    path: "/article/archives/:permalink?",
    component: _0e090268,
    name: "article-archives-permalink"
  }, {
    path: "/item/detail/:code",
    component: _4c3eca28,
    name: "item-detail-code"
  }, {
    path: "/lottery/detail/:id?",
    component: _10985eb7,
    name: "lottery-detail-id"
  }, {
    path: "/news/detail/:permalink?",
    component: _75a9da63,
    name: "news-detail-permalink"
  }, {
    path: "/primeur/detail/:code?",
    component: _355e77d6,
    name: "primeur-detail-code"
  }, {
    path: "/producer/detail/:id?",
    component: _f7405098,
    name: "producer-detail-id"
  }, {
    path: "/shop/blog/:permalink",
    component: _1bba62d0,
    name: "shop-blog-permalink"
  }, {
    path: "/shop/event/:id",
    component: _c6f8ad38,
    name: "shop-event-id"
  }, {
    path: "/staff-review/detail/:reviewId?",
    component: _7123e979,
    name: "staff-review-detail-reviewId"
  }, {
    path: "/staff/detail/:staffId?",
    component: _46f163f4,
    name: "staff-detail-staffId"
  }, {
    path: "/item/detail/:code?/comment/new",
    component: _818a386e,
    name: "item-detail-code-comment-new"
  }, {
    path: "/item/detail/:code?/comment/new/complete",
    component: _7cd86d92,
    name: "item-detail-code-comment-new-complete"
  }, {
    path: "/item/detail/:code?/comment/new/confirm",
    component: _410dc212,
    name: "item-detail-code-comment-new-confirm"
  }, {
    path: "/shop/blog/:permalink/detail/:articleId?",
    component: _8ad4ad14,
    name: "shop-blog-permalink-detail-articleId"
  }, {
    path: "/shop/:permalink?",
    component: _956e1080,
    name: "shop-permalink"
  }, {
    path: "/feature/*",
    component: _17e702e1,
    name: "feature-all"
  }, {
    path: "/",
    component: _27eb63ad,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
