import { ARCHIVE_GIFT, AREA_ID, PRODUCT_CATEGORY_ID, PRODUCT_COLOR_ID, SWEETNESS_OPTIONS } from '~/constants';
import { url } from '~/utils';
import { BooleanLike } from '~/enums';

export const COLOR_MENU = [
  // 赤ワイン
  {
    link: {
      path: url('ITEM_LIST'),
      query: {
        _color: PRODUCT_COLOR_ID.RED,
        _qty: BooleanLike.TRUE,
      },
    },
    textKey: 'Common.globalHeaderMenu.colorType.red',
    img: require('~/assets/images/red_wine.png'),
  },
  // 白ワイン
  {
    link: {
      path: url('ITEM_LIST'),
      query: {
        _color: PRODUCT_COLOR_ID.WHITE,
        _qty: BooleanLike.TRUE,
      },
    },
    textKey: 'Common.globalHeaderMenu.colorType.white',
    img: require('~/assets/images/white_wine.png'),
  },
  // スパーリング
  {
    link: {
      path: url('ITEM_LIST'),
      query: {
        _color: [PRODUCT_COLOR_ID.SPARKLING_WHITE, PRODUCT_COLOR_ID.SPARKLING_ROSE, PRODUCT_COLOR_ID.SPARKLING_RED],
        _qty: BooleanLike.TRUE,
      },
    },
    textKey: 'Common.globalHeaderMenu.colorType.sparkling',
    img: require('~/assets/images/sparkling_wine.png'),
  },
  // ロゼワイン
  {
    link: {
      path: url('ITEM_LIST'),
      query: {
        _color: PRODUCT_COLOR_ID.ROSE,
        _qty: BooleanLike.TRUE,
      },
    },
    textKey: 'Common.globalHeaderMenu.colorType.rose',
    img: require('~/assets/images/rose_wine.png'),
  },
  // オレンジワイン
  {
    link: {
      path: url('ITEM_LIST'),
      query: {
        _color: PRODUCT_COLOR_ID.ORANGE,
        _qty: BooleanLike.TRUE,
      },
    },
    textKey: 'Common.globalHeaderMenu.colorType.orange',
    img: require('~/assets/images/orange_wine.png'),
  },
  // 甘口ワイン
  {
    link: {
      path: url('ITEM_LIST'),
      query: {
        _color: [
          PRODUCT_COLOR_ID.RED,
          PRODUCT_COLOR_ID.WHITE,
          PRODUCT_COLOR_ID.ROSE,
          PRODUCT_COLOR_ID.SPARKLING_WHITE,
          PRODUCT_COLOR_ID.SPARKLING_ROSE,
          PRODUCT_COLOR_ID.SPARKLING_RED,
          PRODUCT_COLOR_ID.ORANGE,
        ],
        _sweetness: [SWEETNESS_OPTIONS.LITTLE_SWEET.id, SWEETNESS_OPTIONS.SWEET.id, SWEETNESS_OPTIONS.VERY_SWEET.id],
        _qty: BooleanLike.TRUE,
      },
    },
    textKey: 'Common.globalHeaderMenu.colorType.sweet',
    img: require('~/assets/images/sweet_wine.png'),
  },
  // 酒精強化ワイン
  {
    link: {
      path: url('ITEM_LIST'),
      query: {
        _ctg: PRODUCT_CATEGORY_ID.FORTIFIED_WINE,
        _qty: BooleanLike.TRUE,
      },
    },
    textKey: 'Common.globalHeaderMenu.colorType.strong',
    img: require('~/assets/images/strong_wine.png'),
  },
];

export const GRAPE_MENU = [
  // カベルネ・ソーヴィニヨン
  {
    textKey: 'Common.globalHeaderMenu.grape.cabernet',
    id: '2',
  },
  // ピノ・ノワール
  {
    textKey: 'Common.globalHeaderMenu.grape.pino',
    id: '3',
  },
  // ネッビオーロ
  {
    textKey: 'Common.globalHeaderMenu.grape.nebbiolo',
    id: '11',
  },
  // サンジョヴェーゼ
  {
    textKey: 'Common.globalHeaderMenu.grape.sangiovese',
    id: '8',
    isSpOnly: true,
  },
  // シャルドネ
  {
    textKey: 'Common.globalHeaderMenu.grape.chardonnay',
    id: '4',
  },
  // ソーヴィニヨン・ブラン
  {
    textKey: 'Common.globalHeaderMenu.grape.sauvignonBlanc',
    id: '9',
  },
  // リースリング
  {
    textKey: 'Common.globalHeaderMenu.grape.riesling',
    id: '13',
  },
];

export const SCENE_MENU = [
  { text: '誕生日、記念日', spOnly: false },
  { text: '普段の夜ご飯と', spOnly: false },
  { text: 'スイーツと一緒に', spOnly: false },
  { text: 'ブライダルギフト', spOnly: true },
];

export const AREA_MENU = [
  {
    textKey: 'Common.globalHeaderMenu.areaMenu.title.france',
    id: AREA_ID.FRANCE,
    subArea: [
      { textKey: 'Common.globalHeaderMenu.areaMenu.title.bordeaux', id: AREA_ID.BORDEAUX },
      { textKey: 'Common.globalHeaderMenu.areaMenu.title.bourgogne', id: AREA_ID.BOURGOGNE },
      { textKey: 'Common.globalHeaderMenu.areaMenu.title.champagne', id: AREA_ID.CHAMPAGNE },
    ],
  },
  {
    textKey: 'Common.globalHeaderMenu.areaMenu.title.italy',
    id: AREA_ID.ITALIA,
  },
  {
    textKey: 'Common.globalHeaderMenu.areaMenu.title.usa',
    id: AREA_ID.USA,
  },
];

export const KEYWORD_MENU = [
  { text: '飲みやすい赤', spOnly: false },
  { text: 'ハーフボトル', spOnly: false },
  { text: 'ナチュラルワイン', spOnly: false },
  { text: '高評価', spOnly: true },
];

export const OTHER_MENU: {
  textKey: string;
  urlKey: 'PRODUCER_LIST' | 'VINTAGE' | 'RANKING' | 'FOOD_DIAGNOSIS' | 'FEATURE_DIAGNOSIS' | 'STAFF_REVIEW_LIST';
  icon: string;
}[] = [
  // 生産者
  {
    textKey: 'Common.globalHeaderMenu.scene.producer',
    urlKey: 'PRODUCER_LIST',
    icon: 'SearchProducersIcon',
  },
  // ヴィンテージ
  {
    textKey: 'Common.globalHeaderMenu.scene.vintage',
    urlKey: 'VINTAGE',
    icon: 'SearchVintagesIcon',
  },
  // ランキング
  {
    textKey: 'Common.globalHeaderMenu.scene.ranking',
    urlKey: 'RANKING',
    icon: 'SearchRankingIcon',
  },
  // 料理
  {
    textKey: 'Common.globalHeaderMenu.scene.food',
    urlKey: 'FOOD_DIAGNOSIS',
    icon: 'SearchFoodDiagnosisIcon',
  },
  // 診断
  {
    textKey: 'Common.globalHeaderMenu.scene.diagnosis',
    urlKey: 'FEATURE_DIAGNOSIS',
    icon: 'SearchDiagnosisIcon',
  },
  // スタッフレビュー
  {
    textKey: 'Common.globalHeaderMenu.scene.staffReview',
    urlKey: 'STAFF_REVIEW_LIST',
    icon: 'SearchStaffReviewIcon',
  },
];

export const OTHER_ITEM_MENU = [
  // グルメなおつまみ
  {
    textKey: 'Common.globalHeaderMenu.category.snack',
    query: {
      _ctg: PRODUCT_CATEGORY_ID.SNACK,
      _qty: BooleanLike.TRUE,
    },
    icon: 'SearchFoodsIcon',
  },
  // ワイングッズ
  {
    textKey: 'Common.globalHeaderMenu.category.wineGoods',
    query: {
      _ctg: PRODUCT_CATEGORY_ID.WINE_GOODS,
      _qty: BooleanLike.TRUE,
    },
    icon: 'SearchGoodsIcon',
  },
  // ワインセラー
  {
    textKey: 'Common.globalHeaderMenu.category.wineCellar',
    query: {
      _ctg: PRODUCT_CATEGORY_ID.WINE_CELLAR,
      _qty: BooleanLike.TRUE,
    },
    icon: 'SearchCellarIcon',
  },
  // その他お酒
  {
    textKey: 'Common.globalHeaderMenu.category.otherAlcohol',
    query: {
      _ctg: PRODUCT_CATEGORY_ID.ALCOHOL_OTHER,
      _qty: BooleanLike.TRUE,
    },
    icon: 'SearchOtherAlcoholIcon',
  },
  // ノンアルコール
  {
    textKey: 'Common.globalHeaderMenu.category.nonAlcohol',
    query: {
      _ctg: PRODUCT_CATEGORY_ID.NON_ALCOHOL,
      _qty: BooleanLike.TRUE,
    },
    icon: 'SearchNonAlcoholIcon',
  },
];

// 子項目のない部分
export const BASIC_INFO_MENU_SP = [
  // 生産者
  {
    textKey: 'Common.globalHeaderMenu.scene.producer',
    link: url('PRODUCER_LIST'),
    icon: 'SearchProducersIcon',
  },
  // ヴィンテージ
  {
    textKey: 'Common.globalHeaderMenu.scene.vintage',
    link: url('VINTAGE'),
    icon: 'SearchVintagesIcon',
  },
  // ワインギフト
  {
    textKey: 'Common.sp.searchTop.category.wineGift',
    link: url('ARCHIVES_DETAIL', { permalink: ARCHIVE_GIFT }),
    icon: 'SearchGiftIcon',
  },
];

// ワイン以外の商品
export const OTHER_ITEM_MENU_SP = [
  // おつまみ
  {
    textKey: 'Common.sp.searchTop.category.snack',
    query: {
      _ctg: PRODUCT_CATEGORY_ID.SNACK,
      _qty: BooleanLike.TRUE,
    },
    icon: 'SearchFoodsIcon',
  },
  // ワイングッズ
  {
    textKey: 'Common.globalHeaderMenu.category.wineGoods',
    query: {
      _ctg: PRODUCT_CATEGORY_ID.WINE_GOODS,
      _qty: BooleanLike.TRUE,
    },
    icon: 'SearchGoodsIcon',
  },
  // ワインセラー
  {
    textKey: 'Common.globalHeaderMenu.category.wineCellar',
    query: {
      _ctg: PRODUCT_CATEGORY_ID.WINE_CELLAR,
      _qty: BooleanLike.TRUE,
    },
    icon: 'SearchCellarIcon',
  },
  // ノンアルコール
  {
    textKey: 'Common.globalHeaderMenu.category.nonAlcohol',
    query: {
      _ctg: PRODUCT_CATEGORY_ID.NON_ALCOHOL,
      _qty: BooleanLike.TRUE,
    },
    icon: 'SearchNonAlcoholIcon',
  },
];

// 子項目のない部分
export const SIMPLE_SEARCH_MENU_SP: {
  textKey: string;
  urlKey: 'STAFF_REVIEW_LIST' | 'FOOD_DIAGNOSIS' | 'ARCHIVES_LIST';
  icon: string;
}[] = [
  // スタッフレビュー
  {
    textKey: 'Common.globalHeaderMenu.scene.staffReview',
    urlKey: 'STAFF_REVIEW_LIST',
    icon: 'SearchStaffReviewIcon',
  },
  // 料理
  {
    textKey: 'Common.globalHeaderMenu.scene.food',
    urlKey: 'FOOD_DIAGNOSIS',
    icon: 'SearchFoodDiagnosisIcon',
  },
  // 特集
  {
    textKey: 'Common.sp.searchTop.archives',
    urlKey: 'ARCHIVES_LIST',
    icon: 'SearchArchivesIcon',
  },
];
