import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import dayjs from 'dayjs';
import uniq from 'lodash-es/uniq';
import Vue from 'vue';
import { getTrackingId } from '~/composables/product/useSearchAPIParam';
import { PRODUCT_RELATED_ARCHIVES_LIMIT, SAME_PRODUCER_ITEMS_LIMIT } from '~/constants';
import { BaseAxiosAction, ECActionPayload } from '~/types/api';
import { ArticleCard } from '~/types/modules/contents';
import {
  ProductShowECResponseSchema,
  ProductShowCMSResponseSchema,
  ProductDetailSelectedFlexibleSetItem,
} from '~/types/modules/product';
import { TopItemDetail } from '~/types/modules/top';
import { ArticleType, BooleanLike } from '~/enums';

export type State = {
  selectedFlexibleSetItems: Array<ProductDetailSelectedFlexibleSetItem>;
};

export const state = (): State => ({
  selectedFlexibleSetItems: [],
});

export const mutations = {
  updateSelectedFlexibleSetItems(state: State, setItem: ProductDetailSelectedFlexibleSetItem) {
    if (state.selectedFlexibleSetItems.length === 0) {
      state.selectedFlexibleSetItems.push(setItem);
      return;
    }
    const setItemIndex = state.selectedFlexibleSetItems.findIndex((item) => item.data.id === setItem.data.id);
    if (setItemIndex === -1) {
      state.selectedFlexibleSetItems.push(setItem);
      return;
    }
    if (setItem.quantity <= 0) {
      state.selectedFlexibleSetItems.splice(setItemIndex, 1);
      return;
    }
    state.selectedFlexibleSetItems[setItemIndex].quantity = setItem.quantity;
  },
  clearSelectedFlexibleSetItems(state: State) {
    state.selectedFlexibleSetItems = [];
  },
};

export type Ctx = DefineActionContext<State, never, typeof mutations>;

export const actions = {
  async fetchECProductDetail(
    this: Vue,
    context: Ctx,
    payload: ECActionPayload<'/api/v1/products/{code}', 'get'>
  ): Promise<ProductShowECResponseSchema> {
    const response = await this.$ecAxios.$get('/api/v1/products/{code}', payload);
    return response;
  },
  async fetchCMSProductDetail(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{ productId: number }>
  ): Promise<ProductShowCMSResponseSchema | null> {
    const { result = [] } = await this.$cmsAxios.$get('/view_articles', {
      ...payload,
      query: {
        '_article_type[eq]': ArticleType.Products,
        '_permalink[eq]': payload.productId,
      },
    });
    return result[0]?.contents || null;
  },
  async fetchCMSDistributionDetail(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{ distributionId: number }>
  ): Promise<any | null> {
    const { result = [] } = await this.$cmsAxios.$get('/view_articles', {
      ...payload,
      query: {
        '_article_type[eq]': 'ec.distribution',
        '_permalink[eq]': payload.distributionId,
      },
    });
    return result[0]?.contents || null;
  },
  // FIXME:返り値の型
  async fetchIntro(this: Vue, context: Ctx, payload: BaseAxiosAction<{ q: string }>): Promise<any> {
    const { q, cancelToken } = payload;
    const {
      response: { docs },
    } = await this.$searchAxios.$get('/select', {
      query: {
        wt: 'json',
        q,
      },
      cancelToken,
    });
    return docs[0];
  },
  async fetchGiftOptions(
    this: Vue,
    context: Ctx,
    payload: ECActionPayload<'/api/v1/products/{code}/gift_option_types', 'get'>
  ) {
    const response = await this.$ecAxios.$get('/api/v1/products/{code}/gift_option_types', payload);
    return response;
  },
  async fetchSimilarProducts(
    this: Vue,
    context: Ctx,
    payload: ECActionPayload<'/api/v1/products/{code}/similar_products', 'get'>
  ) {
    const response = await this.$ecAxios.$get('/api/v1/products/{code}/similar_products', payload);
    return response;
  },
  async fetchDistributionDetail(
    this: Vue,
    context: Ctx,
    payload: ECActionPayload<'/api/v1/distribution_courses/{code}', 'get'>
  ) {
    const response = await this.$ecAxios.$get('/api/v1/distribution_courses/{code}', payload);
    return response;
  },
  async fetchStoreStock(this: Vue, context: Ctx, payload: ECActionPayload<'/api/v1/store_stocks/{product_id}', 'get'>) {
    const response = await this.$ecAxios.$get('/api/v1/store_stocks/{product_id}', payload);
    return response;
  },
  /** 対象枠：同じ生産者の別アイテム、この商品が含まれるセット */
  async fetchProductsForItemDetail(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{ producerId?: number; currentProductCode?: string; relatedSetCodes?: string[] }>
  ): Promise<TopItemDetail[]> {
    const { producerId, currentProductCode, relatedSetCodes, cancelToken } = payload;
    const today = dayjs().toISOString();

    const filterQuery = producerId
      ? `producer_id_i:${producerId} NOT product_code_s:${currentProductCode}`
      : relatedSetCodes
      ? `product_code_s:(${relatedSetCodes.join(' OR ')})`
      : '';
    if (!filterQuery) return [];
    const { response } = await this.$searchAxios.$get('/select', {
      query: {
        wt: 'json',
        q:
          `mst:product AND ` +
          filterQuery +
          ' AND has_stock_flag_i: 1 NOT display_flag_i:0 NOT product_type_s: プリムール' +
          ` NOT sales_start_d:[${today} TO *] NOT sales_end_d:[* TO ${today}]`,
        fl: [
          'product_code_s',
          'product_name_s',
          'sort_r_rating_r',
          'lowest_price_general_i',
          'lowest_cost_price_i',
          'sale_discount_rate_i',
          'color_group_s',
          'shipping_fee_discount_flag_i',
          'sale_info_s',
          'country_s',
          'area_1_s',
          'area_2_s',
          'area_3_s',
          'area_id_1_i',
          'set_type_s',
          'vintage_data_general_sm',
          'raffle_sm',
          'main_image_url_s',
        ],
        // 生産年が若い順（↓商品一覧と同じ）
        sort: 'latest_produced_year_i desc,recommend_flag_i asc,producer_weight_i desc,lowest_price_general_i asc,score desc',
        rows: SAME_PRODUCER_ITEMS_LIMIT,
      },
      cancelToken,
    });

    return response.docs.map(
      (item: any): TopItemDetail => ({
        code: item.product_code_s,
        image: item.main_image_url_s,
        product: {
          product_variant_code_sm: [], // 使用箇所はuseTopPartsと無関係
          // ↑useTopParts用↑
          // ↓TopItemCard用↓
          code: item.product_code_s,
          name: item.product_name_s,
          review_average: item.sort_r_rating_r || 0,
          producing_area: {
            area_1: item.area_1_s || '',
            area_2: item.area_2_s || '',
            area_3: item.area_3_s || '',
            area_id_1: item.area_id_1_i || '',
          },
          display_price: item.lowest_price_general_i || item.lowest_cost_price_i || 0,
          discount_rate: item.sale_discount_rate_i,
          color: item.color_group_s || '',
          is_free_shipping: item.shipping_fee_discount_flag_i,
          is_discount: item.sale_discount_rate_i > 0 ? 1 : 0,
          sale_label: item.sale_info_s || '',
          // ↑TopItemCard用↑
          // ↓カートボタン用↓
          set_type: item.set_type_s,
          children: item.vintage_data_general_sm || [],
          raffle: item.raffle_sm?.map((raffle: string) => {
            // fetchProductsのコピペ
            const splittedVariant = raffle.split('_');
            return {
              id: splittedVariant[0],
              raffleItemId: splittedVariant[1],
              applicationStartTime: splittedVariant[2],
              applicationEndTime: splittedVariant[3],
              announcementTime: splittedVariant[4],
              raffleControlStartTime: splittedVariant[5],
              raffleControlEndTime: splittedVariant[6],
              purchaseStartTime: splittedVariant[7],
              purchaseEndTime: splittedVariant[8],
              productVariantCode: splittedVariant.slice(9).join(''),
            };
          }),
        },
      })
    );
  },
  async fetchRelatedArchives(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{ permalinks: string[]; cmsWineType: string; cmsAreaCategory?: string }>
  ) {
    const { permalinks, cmsWineType, cmsAreaCategory } = payload;
    const timestamp = dayjs().toISOString();
    const trackingId = await getTrackingId();
    const archives: ArticleCard[] = [];

    if (permalinks.length > 0) {
      const permalinksQuery = `article_permalink_s:(${permalinks.join(' OR ')})`;
      const beforePublishStart = `article_publish_start_date_d: [${timestamp} TO *]`;
      const afterPublishEnd = `article_publish_end_date_d: [* TO ${timestamp}]`;
      const {
        response: { docs },
      } = await this.$searchAxios.$get('/select', {
        query: {
          wt: 'json',
          q: `mst:article AND article_type_s:${ArticleType.Archives} AND ${permalinksQuery} NOT ${beforePublishStart} NOT ${afterPublishEnd} NOT is_display_archive_i:${BooleanLike.FALSE}`,
          rows: PRODUCT_RELATED_ARCHIVES_LIMIT,
          fl: ['iid', 'article_permalink_s', 'article_title_s', 'article_pc_thumbnail_s', 'article_pc_main_image_s'],
        },
      });
      archives.push(
        ...(docs?.map((item: any) => ({
          id: item?.iid,
          permalink: item?.article_permalink_s,
          title: item?.article_title_s,
          thumbnailImageSp: item?.article_pc_thumbnail_s || item?.article_pc_main_image_s, // どちらもpcの画像比率
          thumbnailImagePc: item?.article_pc_thumbnail_s || item?.article_pc_main_image_s,
        })) || [])
      );
    } else {
      const { properties } = await this.$recommendAxios.$get('/recommend.json', {
        query: {
          tracking_id: trackingId,
          frame_id: 5,
          'ext.cms_wine_type': cmsWineType,
          'ext.producing': cmsAreaCategory,
        },
      });
      archives.push(
        ...(properties?.map((item: any) => ({
          id: item?.iid,
          permalink: item?.article_permalink,
          title: item?.title,
          thumbnailImageSp: item?.article_pc_thumbnail, // どちらもpcの画像比率
          thumbnailImagePc: item?.article_pc_thumbnail, // recommendからメイン画像帰ってこない
        })) || [])
      );
    }

    if (archives.length === 0 || permalinks.length > 0) {
      return archives;
    }

    // ↓大体無視してオッケー（ごく僅かのケース）↓
    // （本番環境ではrecommendあんまり使われてないし、現時点でPCサムネ未登録の記事が1件しかないので）
    const articlesWithoutPcImg = archives.filter((archive) => !archive.thumbnailImagePc);
    if (articlesWithoutPcImg.length === 0) {
      return archives;
    }
    // recommend.jsonからPCサムネ取れなかった場合はCMSからPCメイン画像を取得
    const { result = [] } = await this.$cmsAxios.$get('/view_articles', {
      query: {
        '_article_type[eq]': ArticleType.Archives,
        '_permalink[in]': `[${articlesWithoutPcImg.map(({ permalink }) => `"${permalink}"`)}]`,
      },
    });
    archives.forEach((archive) => {
      const content = result.find(({ permalink }) => permalink && permalink === archive?.permalink)?.contents;
      // どちらもpcの画像比率
      archive.thumbnailImageSp = archive.thumbnailImageSp || content?.main_image_pc;
      archive.thumbnailImagePc = archive.thumbnailImagePc || content?.main_image_pc;
    });
    // ↑大体無視してオッケー（ごく僅かのケース）↑

    return archives;
  },
  async fetchRelatedArticles(this: Vue, context: Ctx, payload: BaseAxiosAction<{ tagIds: string[] }>) {
    const timestamp = dayjs().toISOString();
    const magazines: ArticleCard[] = [];

    // 読み物を取得
    const tagsQuery = `tag_id_tm:(${payload.tagIds.join(' OR ')})`;
    const beforePublishStart = `article_publish_start_date_d: [${timestamp} TO *]`;
    const afterPublishEnd = `article_publish_end_date_d: [* TO ${timestamp}]`;
    const {
      response: { docs = [] },
    } = await this.$searchAxios.$get('/select', {
      query: {
        wt: 'json',
        q: `mst:article AND article_type_s:${ArticleType.Articles} AND ${tagsQuery} NOT ${beforePublishStart} NOT ${afterPublishEnd} NOT is_display_archive_i:${BooleanLike.FALSE}`,
        rows: PRODUCT_RELATED_ARCHIVES_LIMIT,
        fl: [
          'iid',
          'article_permalink_s',
          'article_title_s',
          'article_pc_thumbnail_s',
          'article_pc_main_image_s',
          'article_category_s',
        ],
      },
    });

    if (docs.length === 0) return [];

    // カテゴリ名を取得
    const categoryIds = uniq(docs.map((item: any) => item.article_category_s));
    let categories: any[] = [];
    if (categoryIds.length > 0) {
      const { result = [] } = await this.$cmsAxios.$get('/view_articles', {
        query: { '_article_type[eq]': ArticleType.Categories, '_article_id[in]': `[${categoryIds.join(',')}]` },
      });
      categories = result;
    }

    magazines.push(
      ...(docs.map(
        (item: any): ArticleCard => ({
          id: item.iid,
          permalink: item.article_permalink_s,
          title: item.article_title_s,
          thumbnailImageSp: item.article_pc_thumbnail_s || item.article_pc_main_image_s, // 横長レイアウトのためどちらもpcの画像比率
          thumbnailImagePc: item.article_pc_thumbnail_s || item.article_pc_main_image_s,
          category: categories.find((category) => category.article_id.toString() === item.article_category_s)?.contents
            .category_name,
        })
      ) || [])
    );
    return magazines;
  },
};

export type Store = DefineStoreModule<'product/detail', State, never, typeof mutations, typeof actions>;
