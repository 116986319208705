import { ref, useContext } from '@nuxtjs/composition-api';
import { jsonp } from 'vue-jsonp';
import { useRandomValueCookie } from '~/composables/useRandomValueCookie';
import { useRuntimeConfig } from '~/composables/useRuntimeConfig';
import { SILVER_EGG_MERCHANT_ID, CATEGORY_ID, SMARTPHONE, SPEC, REQUEST_NUM_SMALL } from '~/constants';
import { convertRecommendItems } from '~/utils';
import {
  ItemResponse,
  RecommendRequestParameter,
  ConvertedRecommendItem,
  TopSilverEggItems,
  RankingTabIndex,
} from '~/types/common/silverEgg';

type FetchRecommendItems = Omit<RecommendRequestParameter, 'callbackQuery' | 'merch' | 'cookie'>;

export const useSilverEggApi = () => {
  const items = ref<any>([]);
  const rqid = ref('');
  const { SILVER_EGG_ORIGIN } = useRuntimeConfig();
  const { silverEggCookie } = useRandomValueCookie();
  const { app } = useContext();
  const isSmartPhone = app.$ua.deviceType() === SMARTPHONE;

  const fetchRecommendItems = async (req: FetchRecommendItems) => {
    const request: RecommendRequestParameter = {
      callbackQuery: 'callback',
      merch: SILVER_EGG_MERCHANT_ID,
      ...req,
      cookie: silverEggCookie.value,
    };
    // ライブラリの都合上undefined入ってるとリクエストが送れない
    // HACK: JSON.stringifyでundefinedの項目をキーごと削除
    const convertedRequest = JSON.parse(JSON.stringify(request));

    // {arrayIndicator: ''} 配列送るときクエリ後の[]を追加せずにurlを生成するため
    // @ts-ignore
    await jsonp(SILVER_EGG_ORIGIN + '/pycre5/jsonp/recommend', convertedRequest, { arrayIndicator: '' })
      .then((res) => {
        items.value = res.items.map((item: ItemResponse) => item[0]);
        rqid.value = res.rqid;
      })
      .catch(() => {
        // 何もしない；枠全体非表示
      });

    return {
      items: items.value,
      rqid: rqid.value,
    };
  };

  const conversionNotify = async (
    req: {
      productCode: string;
      orderNumber: string;
      quantity: number;
      price: number; // 税抜単価
      uuidHash?: string;
      device: string;
    },
    callbackFunctionName?: string
  ) => {
    await jsonp(SILVER_EGG_ORIGIN + '/pycre5/jsonp/purchase', {
      callbackQuery: 'callback',
      callbackName: callbackFunctionName,
      prod: req.productCode, // ※必ず先頭※
      merch: SILVER_EGG_MERCHANT_ID,
      sku: req.productCode,
      order: req.orderNumber,
      qty: req.quantity,
      price: req.price,
      cust: req.uuidHash || silverEggCookie.value, // 会員ID取得できなかった時はcookieを送信
      cookie: silverEggCookie.value,
      device: req.device,
    }).catch(() => {
      // 何もしない
    });
  };

  const clickNotify = async (req: {
    itemId: string;
    spec: string;
    rqid: string; // レコメンドレスポンスのrqidの値
  }) => {
    await jsonp(SILVER_EGG_ORIGIN + '/pycre5/jsonp/click', {
      callbackQuery: 'callback',
      prod: req.itemId, // ※必ず先頭※
      merch: SILVER_EGG_MERCHANT_ID,
      spec: req.spec,
      cookie: silverEggCookie.value,
      cref: req.rqid,
    }).catch(() => {
      // 何もしない
    });
  };

  const fetchConvertedRecommendItems = async (req: {
    spec: RecommendRequestParameter['spec'];
    requestNum: RecommendRequestParameter['num'];
    productIds?: RecommendRequestParameter['prod'];
    categoryIds?: RecommendRequestParameter['cat'];
    excludedCategoryIds?: RecommendRequestParameter['xcat'];
  }) => {
    if (app.$ua.isFromCrawler()) return { items: [], rqid: '' };
    const res = await fetchRecommendItems({
      spec: req.spec,
      num: req.requestNum,
      prod: req.productIds || undefined,
      cat: req.categoryIds || CATEGORY_ID.PRODUCT,
      xcat: req.excludedCategoryIds || undefined,
    });
    const silverEggConvertedItems: ConvertedRecommendItem[] = convertRecommendItems(
      res.items,
      req.categoryIds === CATEGORY_ID.FOOD
    );
    return {
      items: silverEggConvertedItems,
      rqid: res.rqid,
    };
  };

  const fetchHistoryItems = async (
    requestNum: RecommendRequestParameter['num'],
    excludedProductIds?: RecommendRequestParameter['xprod']
  ) => {
    const spec = isSmartPhone ? SPEC.HISTORY_SP : SPEC.HISTORY_PC;
    const res = await fetchRecommendItems({
      spec,
      num: requestNum,
      cat: CATEGORY_ID.PRODUCT,
      xprod: excludedProductIds,
    });
    return {
      items: res.items,
      rqid: res.rqid,
      spec,
    };
  };

  const fetchRankingItems = async (requestIndex: RankingTabIndex, requestNum: RecommendRequestParameter['num']) => {
    const spec = isSmartPhone ? SPEC.RANKING_SP : SPEC.RANKING_PC;
    const categoryParams = [
      {
        categoryIds: CATEGORY_ID.RED_WINE,
        excludedCategoryIds: CATEGORY_ID.WINE_SET,
      },
      {
        categoryIds: CATEGORY_ID.WHITE_WINE,
        excludedCategoryIds: CATEGORY_ID.WINE_SET,
      },
      {
        categoryIds: [
          CATEGORY_ID.SPARKLING_WINE,
          CATEGORY_ID.SPARKLING_RED_WINE,
          CATEGORY_ID.SPARKLING_WHITE_WINE,
          CATEGORY_ID.SPARKLING_ROSE_WINE,
        ],
        excludedCategoryIds: CATEGORY_ID.WINE_SET,
      },
      {
        categoryIds: CATEGORY_ID.WINE_SET,
      },
    ];
    const result = await fetchConvertedRecommendItems({
      spec,
      requestNum,
      ...categoryParams[requestIndex],
    });

    return {
      items: result.items,
      rqid: result.rqid,
      spec,
    };
  };

  const fetchTopPageItems = async (
    requestRankingIndex: RankingTabIndex,
    requestRankingNum: number
  ): Promise<TopSilverEggItems> => {
    const recommendSpec = isSmartPhone ? SPEC.TOP_SP : SPEC.TOP_PC;

    try {
      const [recommendRes, rankingRes, historyRes] = await Promise.all([
        fetchConvertedRecommendItems({
          spec: recommendSpec,
          requestNum: REQUEST_NUM_SMALL,
          categoryIds: CATEGORY_ID.WINE,
        }),
        fetchRankingItems(requestRankingIndex, requestRankingNum),
        fetchHistoryItems(REQUEST_NUM_SMALL),
      ]);

      return {
        recommend: {
          items: recommendRes.items,
          rqid: recommendRes.rqid,
          spec: recommendSpec,
        },
        ranking: {
          items: rankingRes.items,
          rqid: rankingRes.rqid,
          spec: rankingRes.spec,
        },
        history: {
          items: historyRes.items,
          rqid: historyRes.rqid,
          spec: historyRes.spec,
        },
      };
    } catch {
      return {
        recommend: {
          items: [],
          rqid: '',
          spec: '',
        },
        ranking: {
          items: [],
          rqid: '',
          spec: '',
        },
        history: {
          items: [],
          rqid: '',
          spec: '',
        },
      };
    }
  };

  return {
    fetchRecommendItems,
    fetchConvertedRecommendItems,
    fetchRankingItems,
    fetchHistoryItems,
    conversionNotify,
    clickNotify,
    fetchTopPageItems,
  };
};
